import { createRouter, createWebHistory } from 'vue-router'
const Home = ()=>import('../views/home/Home')
const Category = ()=>import('../views/category/Category')
const Profile = ()=>import('../views/profile/Profile')
const ShopCart = ()=>import('../views/shopcart/ShopCart')
const Detail = ()=>import('../views/detail/Detail')
const Register = ()=>import('../views/profile/Register.vue')
const Login = ()=>import('../views/profile/Login.vue')

import {Notify,Toast} from 'vant'


import store from '../store'

const routes = [
  {
    path: '',
    name: 'DefaultHome',
    component: Home,
    meta:{
      title:'图书商城'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      title:'图书商城'
    }
  },
  {
    path: '/category',
    name: 'Category',
    component: Category,
    meta:{
      title:'商品分类'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta:{
      title:'个人中心',
      //需要授权才能访问的页面
      isAuthRequired:true
    }
  },
  {
    path: '/shopcart',
    name: 'ShopCarte',
    component: ShopCart,
    meta:{
      title:'购物车',
      isAuthRequired:true
    }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail,
    meta:{
      title:'商品详情'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title:'用户注册'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title:'登录'
    }
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  //如果一个页面需要登录后才能访问,没有登陆就直接到登陆页面.

  if(to.meta.isAuthRequired && store.state.user.isLogin == false){
     Notify('您还没有登录,请先登录')
     return next('/login')
  }else{
     next()
  }

  document.title = to.meta.title
  
  
})

export default router
