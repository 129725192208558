import { createStore } from 'vuex'

export default createStore({
  state: {
    cartCount:3,
    user:{
      isLogin:window.localStorage.getItem('token') ? true : false
    }
  },
  getters: {
  },
  mutations: {
    setIsLogin(state,payload){
      state.user.isLogin = payload

    }
  },
  actions: {
  },
  modules: {
  }
})
