<template>
   <router-view/>

  <div id="nav">
    <router-link class="tab-bar-item" to="/">
       <div class="icon"><i class="iconfont icon-shouye"></i></div>
       <div>首页</div>
    </router-link> 
    <router-link class="tab-bar-item" to="/category">
       <div class="icon"><i class="iconfont icon-fenlei"></i></div>
       <div>分类</div>
    </router-link> 
    <router-link class="tab-bar-item" to="/shopcart">
       <van-badge :content="$store.state.cartCount" max="99">
         <div class="icon"><i class="iconfont icon-gouwuche"></i></div>
       </van-badge>
       <div>购物车</div>
    </router-link> 
    <router-link class="tab-bar-item" to="/profile">
       <div class="icon"><i class="iconfont icon-wode"></i></div>
       <div>我的</div>
    </router-link> 
  </div>
</template>

<style lang="scss">

@import "~assets/css/base.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  background-color: #F6F6F6;
  display: flex;
  position: fixed;
  left: 0;
  right:0;
  bottom: 0;
  box-shadow: 0 -3px 1px rgba(100,100,100,0.1);
  a {
    color: #2c3e50;
    &.router-link-exact-active {
      color: var(--color-tint);
    }
  }
  .tab-bar-item{
    flex:1;
    text-align: center;
    height: 50px;
    font-size: var(--font-size);
  
     .icon{
       margin-top: 5px;
       vertical-align: middle;
       display: inline-block;
        
     }
  }
}
</style>
